.about__polygon-end{
    width: 100%;
    height: 5rem;
    background-color: var(--light-bg-color);
    clip-path: polygon(0 75%, 100% 0, 100% 100%, 0 100%);    right: 0;
    position: absolute;
    margin-top: -4.9rem;
}
/* ----- */
.service{
    height: 100vh;
    min-height: 654px;
    background-color: var(--light-bg-color);
    z-index: 10;
}
.service__container{
    padding: 1rem .5rem 2.5rem 0;
    
}
.service .title{
    font-weight: var(--bold-weight);
    font-size: var(--h1-font-size);
    text-align: center;
    margin-bottom: 1rem;
}
.service .subheader{
    margin-bottom: 1.5rem;
    line-height: 1.4rem;
    text-align: center;
    font-weight: var(--medium-weight);
}
.service__grid{
    grid-template-columns: repeat(2,1fr);
    justify-items: center;
    width: 100%;
}
.service__item{
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 10rem;
    width: 100%;
    padding: 0.5rem;
    background-color: white;
    box-shadow: 0 0 0 0px black, 0.625em 0.625em 0 0 var(--hero-button-shadow);
    z-index: 3;
}
.item__icon{
    width: 70px;
    /* background-color: var(--light-bg-color);
    border-radius: 25%;
    padding: .3rem; */
}
.item__subheader *{
    font-size: 1rem;
    font-weight: 600;
}
.service__images{
    display: none;
    z-index: 0;
}
.service__footer{
    margin-top: 2rem;
}
.service__buttons{
    text-align: center;
    z-index: 10;
}
@media screen and (min-height:700px){
    .service__container{
        padding: 2.5rem 0.5rem 2.5rem 0;
    }
}
@media screen and (min-width: 560px){
    .service__info{
        margin: 0 5rem;
    }
}
@media screen and (min-width:768px){
    .about__polygon-end{
        height: 7rem;
        margin-top: -6.9rem;
    }
    .service__grid{
        grid-template-columns: repeat(3, 1fr);
    }
    .service__container {
        padding: 0 0.5rem 2.5rem 0;
        display: flex;
        flex-direction: row-reverse;
    }
    .service__info{
        max-width: 71%;
        width: 100%;
    }
    .service__images{
        display: block;
        position: absolute;
        left: -10.5rem;
    }
    .service__item{
        max-width: 15rem;
    }
    .item__subheader{
        padding: 0 1rem 1rem 1rem;
    }
    .item__icon{
        width: 80px !important;
    }
}
@media screen and (min-width:1024px){
    .service__title{
        margin-top: 2rem;
        font-size: 2rem !important;
    }
    .service__subheader{
        font-size: 1rem;
    }
    .service__grid{
        grid-template-columns: repeat(3, 1fr);
    }
    .service__container {
        padding: 0 0.5rem 2.5rem 0;
        display: flex;
        flex-direction: row-reverse;
    }
    .service__info{
        max-width: 71%;
        width: 100%;
    }
    .service__images{
        position: absolute;
        left: 0;
    }
}