.contact{
    height: initial;
    padding-top: 0;
}
.contact .title{
    margin-bottom: 2rem;
}
.contact__titles{
    background-color: var(--light-bg-color);
    margin-bottom: var(--mb-2-5);
    padding: 2.5rem 1rem;
    box-shadow: 0 0 0 0px black, 0.625em 0.625em 0 0 var(--hero-button-shadow);
}
.contact__subheader{
    margin-bottom: 2rem;
    font-weight: var(--medium-weight);
}
.contact__form{
    text-align: -webkit-center;
}
.contact-div{
    display: flex;
    flex-direction: column;
    max-width: 500px;
    gap: 1rem;
    margin-bottom: 1rem;
}
.contact-div *{
    width: 100%;
}
.contact__form input{
    width: 100%;
    max-width: 500px;
    padding: 0.5rem;
    padding-left: 0.7rem;
    color: var(--text-color);
    height: 2.5rem;
    border: 1px solid var(--hero-button-shadow);
}
.contact__form button{
    margin-top: 1rem;
} 

.contact__buttons{
    display: inline-flex;
    align-items: center;
    column-gap: 1rem;
}
.contact-button{
    display: flex;
    width: 8.5rem;
    padding: 1em 1em;
}
@media screen and (max-height: 700px){
    /* .contact{
        padding-top: 15rem;
    } */
}
@media screen and (min-width: 429px){
    .contact-div{
        flex-direction: row;
    }
}
@media screen and (min-width: 768px){
    .contact{
        padding-top: 4.5rem;

    }
}