.service__polygon-end{
    width: 100%;
    height: 5rem;
    background-color: white;
    clip-path: polygon(0 0, 100% 75%, 100% 100%, 0% 100%);    position: absolute;
    margin-top: -4.9rem;
}
.delivery__container{
    padding: 2.5rem 0 2.5rem 0;
}
.delivery__title span{
    font-style: oblique;
}
.delivery__subtitles{
    display: flex;
    row-gap: 0.7rem;
    flex-direction: column;
    align-content: center;
    min-width: fit-content;
    max-width: fit-content;
    margin-inline: auto;
}
.delivery__subtitle{
    display: flex;
    align-items: flex-start;
}
.delivery__subtitle h1{
    font-size: 2.3rem;
}
.delivery__subtitle span{
    font-size: var(--h2-font-size);
    font-weight: 500;
    padding: .5rem;
    background-color: var(--light-bg-color);
    border-radius: 0.75em;
}
#subtitle{
    margin-left: 2rem;
}
.delivery__images{
    text-align: center;
}
.delivery-img{
    width: 400px;
}
#surprise{
    display: none;
    padding: 0;
}
@media screen and (min-width:768px){
    .service__polygon-end{
        height: 7rem;
        margin-top: -6.9rem;
    }
    .delivery__info{
        display: flex;
        
    }
    .delivery__subtitles{
        justify-content: center;
        row-gap: 3.69rem;
        margin-top: -3rem;
    }
    .delivery__subtitle h1{
        font-size: 2.7rem;
    }
    .delivery__subtitle span{
        font-size: var(--h1-font-size);
        padding: 0.8rem 0.5rem 0.8rem 0.8rem;
    }
    .delivery__images{
        margin-top: -7rem;
        margin-left: -7rem;
        text-align: center;
        z-index: -1;
    }
}
@media screen and (min-width: 850px){
    .delivery__subtitle h1{
        font-size: 3rem;
    }
    .delivery__subtitle span{
        font-size: 1.5rem;
        padding: .7rem;
    }
    #surprise{
        display: inline;
    }
}
@media screen and (min-width: 1021px){
    .delivery__title{
        margin-top: 3rem;
        font-size: 2.2rem !important;
    }
    .delivery__subtitle h1{
        font-size: 5rem;
    }
    .delivery__subtitle span{
        font-size: 2rem;
        padding: 1.2rem 1rem;
    }
    #subtitle{
        margin-left: 3.6rem;
    }
    
}