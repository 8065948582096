.about{
    background-color: black;
    padding-top: 6rem;
    padding-bottom: 0;
    min-height: 654px;
}
.about__container{
    gap: 2.5rem;
}
.about__polygon-start{
    width: 100%;
    height: 5rem;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 25%, 0 100%);    left: 0;
    right: 0;
    position: absolute;
    margin-top: -0.1rem;
}
.about .title{
    color: #fff;
    margin-bottom: 1rem;
}
.about__subheader{
    color: rgb(191 191 191);
    margin-bottom: 1rem !important;
    line-height: 1.4rem;
}
.about__img{
    display: flex;
    column-gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    z-index: 2;
}
.about__img-overlay *
{
    width: 160px;
}
.about__img-one
{
    padding-top:1.7rem;
}
.about__info{
    text-align: center;
}
.about__img-overlay{
    overflow: var(--img-hidden);
}
.about__img-overlay *
{
    transition: var(--img-transition);
}
.about__img-overlay *:hover
{
    transform: var(--img-scale);
}
.about-button::before{
    box-shadow: 0 0 0 2px black;
}
.about-button:hover::before {
    box-shadow:none;
}
@media screen and (min-height: 668px){
    .about{
        padding-top: 9rem;
    }
}
@media screen and (min-width: 768px){
    .about{
        padding-top: 10rem;
    }
    .about__container {
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
    }
    .about .title{
        margin-bottom: 2rem;
    }
    .about__subheader{
        margin-bottom: 2.5rem !important;
    }
    .about__info{
        margin-top: 4rem;
    }
    .about__polygon-start{
        height: 7rem;
    }
    .about__img-one{
        width: 200px;
    }
    .about__img-two{
        width: 200px;
    }
}
@media screen and (min-width: 1024px){
    .about__info{
        margin-top: 5rem;
    }
    .about__img{
        column-gap: 1.5rem;
    }
    .about__img-two{
        width: 300px !important;
    }
    .about__img-one{
        padding-top: 2rem;
        width: 280px !important;
    }
    .about__title{
        font-size: 2rem !important;
    }
    .about__subheader{
        font-size: 1rem;
    }
}